import axios from "axios";

const Gag = async function(){

   const res = await axios.get("https://hot-pepperoni.com/api/bobgag");
   let index = Math.floor(Math.random() * res.data.data.length ); 
   let gagItem = res.data.data[index];

    const tmpl = `
        <div class="card">
        <img src="${ gagItem.media }" />
            <div class="card-body">
                <h1>"${ gagItem.name }"<h1/>
            </div>
        </div>
    `;
    return tmpl;
};

export default Gag;